import React from 'react';
import Accounts from './accounts';
import Graph from "./graph";
import Network from './network';
import "./stats.scss";
import useReferralFactory from '../../../hooks/use.referral.factory';
export default function StatsSettings() {

  // include referral factory widget
  useReferralFactory();

  return (
    <div className="profile-page  fancyscroll " data-testid="stats-panel">
      <div className="profile-page-content left">
        <Accounts />
        <Graph />
      </div>
      <div className="profile-separator" />
      <div className="profile-page-content right">
        <Network />
      </div>
      <div style={{ display: 'none' }}></div>
    </div>
  );
}